import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";

const json = {
  1: {
    unit: "Fluency Time! 1",
    id: "SB4-2023-FT1-P30-E1",
    audio: "Audios/Page30/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen. Read and say. <headphone name='43' src='Audios/Page30/tieude-e1.mp3'></headphone>",
        color: "rgb(0, 166, 81)",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page30/E1/1.jpg",
          audioUrl: "Audios/Page30/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page30/E1/2.jpg",
          audioUrl: "Audios/Page30/hinh2.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Fluency Time! 1",
    id: "SB4-2023-FT1-P30-E2",
    audio: "Audios/Page30/audio-e2.mp3",
    video: "",
    component: Circle_Write,
    exerciseKey: "img/FriendsPlus/Page30/E2/Key/answerKey.png",
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and circle the answer <headphone name='44' src='Audios/Page30/tieude-e2.mp3'></headphone> <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "rgb(0, 166, 81)",
      },
    ],
    question: {
      Write: {
        inputStyle: {},
        answers: [],
        initialValue: [],
      },
      Circle: {
        initialWordStyle: {
          padding: "5px",
          fontWeight: 600,
          color: "",
          // fontSize: 27,
          borderRadius: "50%",
          // marginInline: '-1px',
        },
        selectWordStyle: { border: "3px solid" },
        limitSelect: 1,
        listWords: ["English / Vietnamese", "Mom / Dad", "rice / noodles"],
        answers: ["0-0", "1-0", "2-0"],
        initialValue: [],
      },
      Layout: /*html*/ `
      <div style='display: flex; gap: 20px; width: 996px'>
        <div style='flex: 3; display: flex; flex-direction: column; gap: 20px;'>
          <div style='display: flex; gap: 10px;'>
            <span>1.</span>
            <span>Kate has <input id='0'  type='Circle' /> homework.</span>
          </div>
          <div style='display: flex; gap: 10px;'>
            <span>2.</span>
            <span><input id='1'  type='Circle' /> is helping her.</span>
          </div>
          <div style='display: flex; gap: 10px;'>
            <span>3.</span>
            <span>Mom has a picture of <input id='2'  type='Circle' />.</span>
          </div>
        </div>
        <div style='flex: 2'><img style='width:100%' src='img/FriendsPlus/Page30/E2/1.jpg'/></div>
      </div>
    `,
    },
  },
  3: {
    unit: "Fluency Time! 1",
    id: "SB4-2023-FT1-P30-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Ask and answer. <img style='width:1cm' src='img/FriendsPlus/Page11/E4/a.jpg'/>",
        color: "rgb(0, 166, 81)",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page30/E3/1.jpg",
        },
        // {
        // 	url: 'img/FriendsPlus/Page30/E1/2.jpg',
        // 	audioUrl: 'Audios/Page30/hinh2-e1-p30.mp3',
        // },
      ],
    ],
  },
};

export default json;
