import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    unit: "Unit 2",
    id: "SB4-2023-U2-P18-E1",
    audio: "Audios/Page18/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='23' src='Audios/Page18/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page18/E1/1.jpg",
          audioUrl: "Audios/Page18/office.mp3",
        },
        {
          url: "img/FriendsPlus/Page18/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page18/E1/3.jpg",
          audioUrl: "Audios/Page18/farm.mp3",
        },
        {
          url: "img/FriendsPlus/Page18/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page18/E1/5.jpg",
          audioUrl: "Audios/Page18/bank.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 2",
    id: "SB4-2023-U2-P18-E2",
    audio: "Audios/Page18/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and sing. <headphone name='24' src='Audios/Page18/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page18/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 2",
    id: "SB4-2023-U2-P18-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3",
        title: "Sing and do.",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page18/E2/1.jpg" }]],
  },
};

export default json;
