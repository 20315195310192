import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import ScoreByColor from "../../components/ExcerciseTypes/Design/ScoreByColor";
const json = {
  1: {
    unit: "Fluency Time 1",
    id: "SB4-2023-FL1-P31-E1",
    audio: "Audios/Page31/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='45' src='Audios/Page31/tieude-e1.mp3'></headphone>",
        color: "#00a651",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page31/E1/1.jpg",
          audioUrl: "Audios/Page31/fire-truck.mp3",
        },
        {
          url: "img/FriendsPlus/Page31/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page31/E1/3.jpg",
          audioUrl: "Audios/Page31/ladder.mp3",
        },
        {
          url: "img/FriendsPlus/Page31/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page31/E1/5.jpg",
          audioUrl: "Audios/Page31/helmet.mp3",
        },
        {
          url: "img/FriendsPlus/Page31/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page31/E1/7.jpg",
          audioUrl: "Audios/Page31/uniform.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Fluency Time 1",
    id: "SB4-2023-FL1-P31-E2",
    audio: "Audios/Page31/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and sing. <headphone name='46' src='Audios/Page31/tieude-e2.mp3'></headphone>",
        color: "#00a651",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page31/E2/1.jpg" }]],
  },
  3: {
    unit: "Fluency Time 1",
    id: "SB4-2023-FL1-P31-E3",
    audio: "",
    video: "",
    component: T6,
    maxLength: 10,
    inputSize: 130,
    hideBtnFooter:true,
    padding: 0,
    exerciseKey: "img/FriendsPlus/Page31/E3/Key/answerKey.png",
    textAlign: "center",
    // hideRulesRequired: true,
    hintBox: [
      {
        src: ["ladder", "<s>yellow<s>", "fire truck", "helmet"],
        borderColor: "black",
        width: 500,
      },
    ],
    titleQuestion: [
      {
        num: "3",
        title:
          "Read again and write. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#00a651",
      },
    ],
    titleImage: "",
    questionImage: [],
    questions: [
      {
        title: `
        <div style='margin:10px 50px'><hintbox id='0'></hintbox></div>
        <div style='display:flex;line-height:55px'>
          <div style='margin-right:50px'>
            <b>1.</b> Jack’s uniform is brown and <u>&ensp;yellow&ensp;</u>.<br>
            <b>2.</b> His#is yellow and red.
          </div>
          <div>
            <b>3.</b> The#is very big and fast.<br>
            <b>4.</b> A#is on the fire truck.
          </div>
        </div>
        
        `,
        answer: ["helmet", "fire truck", "ladder"],
      },
    ],
  },
  4: {
    unit: "Fluency Time 1",
    id: "SB4-2023-FL1-P31-E4",
    audio: "",
    video: "",
    component: T6,
    recorder: true,
    question: [],
    textareaStyle: {
      width: 700,
    },
    titleQuestion: [
      {
        num: "4",
        title:
          "<img style='width:25mm' src='img/FriendsPlus/Page11/E3/c.jpg'/> What things does a firefighter need to fight fires? Make a checklist. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#00a651",
      },
    ],
    questions: [
      {
        title: `
        <div >
          <textarea id='0' rows='5'></textarea>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
  5: {
    unit: "Fluency Time 1",
    id: "SB4-2023-FL1-P31-E5",
    audio: "",
    video: "",
    titleQuestion: [
      {
        num: "5",
        title: "Look and color.",
        color: "#00a651",
      },
    ],
    hideBtnFooter: true,
    component: DesignUnderLine,
    // recorder: true,
    question: [],
    totalInput: 1,

    questionImage: [
      [{ url: "img/FriendsPlus/Page31/E5/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page31/E5/2.jpg" },
        {
          url: "img/FriendsPlus/Page31/E5/3.jpg",
          input: 1,
          isCorrect: true,
          audioUrl: "Audios/success-Sound.mp3",
        },
        { url: "img/FriendsPlus/Page31/E5/4.jpg" },
        {
          url: "img/FriendsPlus/Page31/E5/5.jpg",
          input: 1,
          isCorrect: true,
          audioUrl: "Audios/success-Sound.mp3",
        },
        { url: "img/FriendsPlus/Page31/E5/6.jpg" },
        {
          url: "img/FriendsPlus/Page31/E5/7.jpg",
          input: 1,
          isCorrect: true,
          audioUrl: "Audios/success-Sound.mp3",
        },
        { url: "img/FriendsPlus/Page31/E5/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page31/E5/9.jpg" }],
    ],
  },
};

export default json;
