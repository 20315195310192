import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Starter",
    id: "SB4-2023-Starter-P9-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Read and say. <img style='width:1cm' src='img/FriendsPlus/Page11/E4/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page9/E1/1.jpg",
        },
      ],
    ],
  },
  2: {
    unit: "Starter",
    id: "SB4-2023-Starter-P9-E2",
    audio: "",
    video: "",
    component: D1,
    hideBtnFooter:true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Write and draw about you. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page9/E2/1.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page9/E2/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page9/E2/3.jpg",
          input: true,
          answer: "",
        },
        {
          url: "img/FriendsPlus/Page9/E2/4.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page9/E2/5.jpg",
        },
        {
          url: "img/FriendsPlus/Page9/E2/6.jpg",
          input: true,
          answer: "",
        },
        {
          url: "img/FriendsPlus/Page9/E2/7.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page9/E2/8.jpg",
        },
        {
          url: "img/FriendsPlus/Page9/E2/9.jpg",
          input: true,
          answer: "",
        },
        {
          url: "img/FriendsPlus/Page9/E2/10.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page9/E2/11.jpg",
        },
        {
          url: "img/FriendsPlus/Page9/E2/12.jpg",
          input: true,
          answer: "",
        },
        {
          url: "img/FriendsPlus/Page9/E2/13.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page9/E2/14.jpg",
        },
        {
          url: "img/FriendsPlus/Page9/E2/15.jpg",
          input: true,
          answer: "",
        },
        {
          url: "img/FriendsPlus/Page9/E2/16.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page9/E2/17.jpg",
        },
        {
          url: "img/FriendsPlus/Page9/E2/18.jpg",
          input: true,
          answer: "",
        },
        {
          url: "img/FriendsPlus/Page9/E2/19.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page9/E2/20.jpg",
        },
        {
          url: "img/FriendsPlus/Page9/E2/21.jpg",
          input: true,
          answer: "",
        },
        {
          url: "img/FriendsPlus/Page9/E2/22.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page9/E2/23.jpg",
        },
        {
          url: "img/FriendsPlus/Page9/E2/24.jpg",
          input: true,
          answer: "",
        },
        {
          url: "img/FriendsPlus/Page9/E2/25.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page9/E2/26.jpg",
        },
        {
          url: "img/FriendsPlus/Page9/E2/27.jpg",
          input: true,
          answer: "",
        },
        {
          url: "img/FriendsPlus/Page9/E2/28.jpg",
        },
      ],

      [
        {
          url: "img/FriendsPlus/Page9/E2/29.jpg",
        },
      ],
    ],
  },
  3: {
    unit: "Starter",
    id: "SB4-2023-Starter-P9-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3",
        title:
          "Talk about you. <img style='width:1cm' src='img/FriendsPlus/Page11/E4/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page9/E3/1.jpg",
        },
      ],
    ],
  },
};

export default json;
