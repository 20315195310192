import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Starter",
    id: "SB4-2023-Starter-P4-E1",
    audio: "Audios/Page4/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and sing. <headphone name='01' src='Audios/Page4/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page4/E1/1.jpg",
        },
      ],
    ],
  },
  2: {
    unit: "Starter",
    id: "SB4-2023-Starter-P4-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title: "Sing and do.",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page4/E1/1.jpg",
        },
      ],
    ],
  },
  3: {
    unit: "Starter",
    id: "SB4-2023-Starter-P4-E3",
    audio: "Audios/Page4/audio-e3.mp3",
    video: "",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and read. <headphone name='02' src='Audios/Page4/tieude-e3.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page4/E3/1.jpg",
          audioUrl: "Audios/Page4/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page4/E3/2.jpg",
          audioUrl: "Audios/Page4/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page4/E3/3.jpg",
          audioUrl: "Audios/Page4/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page4/E3/4.jpg",
          audioUrl: "Audios/Page4/hinh4.mp3",
        },
      ],
      [
        {
          audioUrl: "Audios/Page4/hinh5.mp3",
          url: "img/FriendsPlus/Page4/E3/5.jpg",
        },
      ],
    ],
  },
};

export default json;
