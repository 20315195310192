import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Starter",
    id: "SB4-2023-Starter-P6-E1",
    audio: "Audios/Page6/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point and repeat. <headphone name='04' src='Audios/Page6/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page6/E1/1.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page6/E1/2.jpg",
        },

        {
          url: "img/FriendsPlus/Page6/E1/3.jpg",
          audioUrl: "Audios/Page6/monday.mp3",
        },
        {
          url: "img/FriendsPlus/Page6/E1/4.jpg",
          audioUrl: "Audios/Page6/tuesday.mp3",
        },

        {
          url: "img/FriendsPlus/Page6/E1/5.jpg",
          audioUrl: "Audios/Page6/wednesday.mp3",
        },
        {
          url: "img/FriendsPlus/Page6/E1/6.jpg",
          audioUrl: "Audios/Page6/thursday.mp3",
        },
        {
          url: "img/FriendsPlus/Page6/E1/7.jpg",
          audioUrl: "Audios/Page6/friday.mp3",
        },
        {
          url: "img/FriendsPlus/Page6/E1/8.jpg",
          audioUrl: "Audios/Page6/saturday.mp3",
        },
        {
          url: "img/FriendsPlus/Page6/E1/9.jpg",
          audioUrl: "Audios/Page6/sunday.mp3",
        },
        {
          url: "img/FriendsPlus/Page6/E1/10.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page6/E1/11.jpg",
        },
      ],
    ],
  },
  2: {
    unit: "Starter",
    id: "SB4-2023-Starter-P6-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Ask and answer. <img style='width:1cm' src='img/FriendsPlus/Page11/E4/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page6/E2/1.jpg",
        },
      ],
    ],
  },
  3: {
    unit: "Starter",
    id: "SB4-2023-Starter-P6-E3",
    audio: "Audios/Page6/audio-e3.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and sing. <headphone name='05' src='Audios/Page6/tieude-e3.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page6/E3/1.jpg",
        },
      ],
    ],
  },
  4: {
    unit: "Starter",
    id: "SB4-2023-Starter-P6-E4",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "4",
        title: "Sing and do.",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page6/E3/1.jpg",
        },
      ],
    ],
  },
};

export default json;
