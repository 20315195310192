import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 8",
    id: "SB4-2023-U8-P60-E1",
    audio: "Audios/Page60/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point and repeat. <headphone name='95' src='Audios/Page60/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page60/E1/1.jpg",
          audioUrl: "Audios/Page60/candy.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page60/E1/3.jpg",
          audioUrl: "Audios/Page60/balloon.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page60/E1/5.jpg",
          audioUrl: "Audios/Page60/present.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page60/E1/7.jpg",
          audioUrl: "Audios/Page60/candle.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 8",
    id: "SB4-2023-U8-P60-E2",
    audio: "Audios/Page60/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='96' src='Audios/Page60/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page60/E1/1.jpg",
          audioUrl: "Audios/Page60/candy.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page60/E1/3.jpg",
          audioUrl: "Audios/Page60/balloon.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page60/E1/5.jpg",
          audioUrl: "Audios/Page60/present.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page60/E1/7.jpg",
          audioUrl: "Audios/Page60/candle.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 8",
    id: "SB4-2023-U8-P60-E3",
    audio: "Audios/Page60/audio-e3.mp3",
    video: "Videos/Unit 08.mp4",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and read. <headphone name='97' src='Audios/Page60/tieude-e3.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page60/E3/1.jpg",
          audioUrl: "Audios/Page60/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E3/2.jpg",
          audioUrl: "Audios/Page60/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page60/E3/3.jpg",
          audioUrl: "Audios/Page60/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E3/4.jpg",
          audioUrl: "Audios/Page60/hinh4.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page60/E3/5.jpg",
          audioUrl: "Audios/Page60/hinh5.mp3",
        },
        {
          url: "img/FriendsPlus/Page60/E3/6.jpg",
          audioUrl: "Audios/Page60/hinh6.mp3",
        },
      ],
    ],
  },
};

export default json;
