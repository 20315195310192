import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 6",
    id: "SB4-2023-U6-P49-E1",
    audio: "Audios/Page49/audio-e1.mp3",
    video: "",
    component: D1,
    maxLength: 1,
    fontSize: 27,
    exerciseKey: "img/FriendsPlus/Page49/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and put the pictures in the correct order. Write. <headphone name='78' src='Audios/Page49/tieude-e1.mp3'></headphone> <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [{ url: "img/FriendsPlus/Page49/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page49/E1/2.jpg" },
        { url: "img/FriendsPlus/Page49/E1/3.jpg", input: true, answer: "2" },
        { url: "img/FriendsPlus/Page49/E1/4.jpg" },
        { url: "img/FriendsPlus/Page49/E1/5.jpg", input: true, answer: "4" },
      ],
      [{ url: "img/FriendsPlus/Page49/E1/6.jpg" }],
      [
        { url: "img/FriendsPlus/Page49/E1/7.jpg" },
        { url: "img/FriendsPlus/Page49/E1/8.jpg", input: true, answer: "1" },
        { url: "img/FriendsPlus/Page49/E1/9.jpg" },
        { url: "img/FriendsPlus/Page49/E1/10.jpg", input: true, answer: "3" },
      ],
    ],
  },

  2: {
    unit: "Unit 6",
    id: "SB4-2023-U6-P49-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title: "Look at the pictures. Ask and answer.",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page49/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 6",
    id: "SB4-2023-U6-P49-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3 ",
        title: "Mime an activity for your friend to guess.",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page49/E3/1.jpg" }]],
  },
  4: {
    unit: "Unit 6",
    id: "SB4-2023-U6-P49-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page49/E4/Key/answerKey.png",
    component: DesignUnderLine,
    // maxLength: ,
    totalInput: 3,
    titleQuestion: [
      {
        num: "4",
        title:
          "Choose the best sentences for the poster. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [{ url: "img/FriendsPlus/Page49/E4/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page49/E4/2.jpg" },
        { url: "img/FriendsPlus/Page49/E4/3.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page49/E4/4.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page49/E4/5.jpg" },
        { url: "img/FriendsPlus/Page49/E4/6.jpg", input: 1 },
        { url: "img/FriendsPlus/Page49/E4/7.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page49/E4/8.jpg" },
        { url: "img/FriendsPlus/Page49/E4/9.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page49/E4/10.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page49/E4/11.jpg" },
        { url: "img/FriendsPlus/Page49/E4/12.jpg", input: 2 },
        { url: "img/FriendsPlus/Page49/E4/13.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page49/E4/14.jpg" }],
      [
        { url: "img/FriendsPlus/Page49/E4/15.jpg" },
        { url: "img/FriendsPlus/Page49/E4/16.jpg", input: 3 },
        { url: "img/FriendsPlus/Page49/E4/17.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page49/E4/18.jpg" },
        { url: "img/FriendsPlus/Page49/E4/19.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page49/E4/20.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page49/E4/21.jpg" }],
    ],
  },
  5: {
    unit: "Unit 6",
    id: "SB4-2023-U6-P49-E5",
    audio: "",
    video: "",
    isHiddenCheck: true,
    exerciseKey: "",
    component: T6,
    // maxLength: 7,
    inputSize: 40,
    textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: "5",
        title:
          "Make a poster about a school trip. Look at page 48 to help you. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div >
          <textarea id='0' rows='5'></textarea>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
