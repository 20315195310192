import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 10",
    id: "SB4-2023-U10-P79-E1",
    audio: "Audios/Page79/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='125' src='Audios/Page79/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page79/E1/1.jpg" },
        {
          url: "img/FriendsPlus/Page79/E1/2.jpg",
          audioUrl: "Audios/Page79/horn.mp3",
        },
        {
          url: "img/FriendsPlus/Page79/E1/3.jpg",
          audioUrl: "Audios/Page79/corn.mp3",
        },
        {
          url: "img/FriendsPlus/Page79/E1/4.jpg",
          audioUrl: "Audios/Page79/storm.mp3",
        },
        { url: "img/FriendsPlus/Page79/E1/5.jpg" },
        {
          url: "img/FriendsPlus/Page79/E1/6.jpg",
          audioUrl: "Audios/Page79/car.mp3",
        },
        {
          url: "img/FriendsPlus/Page79/E1/7.jpg",
          audioUrl: "Audios/Page79/farm.mp3",
        },
        {
          url: "img/FriendsPlus/Page79/E1/8.jpg",
          audioUrl: "Audios/Page79/star.mp3",
        },
        { url: "img/FriendsPlus/Page79/E1/9.jpg" },
      ],
    ],
  },
  2: {
    unit: "Unit 10",
    id: "SB4-2023-U10-P79-E2",
    audio: "Audios/Page79/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='126' src='Audios/Page79/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page79/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 10",
    id: "SB4-2023-U10-P79-E3",
    audio: "",
    video: "",
    isHiddenCheck: true,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page79/E3/Key/answerKey.png",
    component: T6,
    maxLength: 6,
    inputSize: 100,
    titleQuestion: [
      {
        num: "3",
        title:
          "Read the chant again. Say the words with ar and or. Write. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img  src='img/FriendsPlus/Page79/E2/1.jpg'/>
        <div style='text-align:center'>#,#,#,#,#,#,#</div>
        
        `,
        answer: [
          "car / park / short / scarf / shark / storm / dark",
          "car / park / short / scarf / shark / storm / dark",
          "car / park / short / scarf / shark / storm / dark",
          "car / park / short / scarf / shark / storm / dark",
          "car / park / short / scarf / shark / storm / dark",
          "car / park / short / scarf / shark / storm / dark",
          "car / park / short / scarf / shark / storm / dark",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 10",
    id: "SB4-2023-U10-P79-E4",
    audio: "Audios/Page79/audio-e4.mp3",
    video: "",
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page79/E4/Key/answerKey.png",
    component: T6,
    maxLength: 2,
    textAlign: "center",
    inputSize: 45,
    titleQuestion: [
      {
        num: "4",
        title:
          "Listen and write the missing letters. Match. <headphone name='127' src='Audios/Page79/tieude-e4.mp3'></headphone> <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex;'>
            
            
            <div style='text-align:center'>
              <img  src='img/FriendsPlus/Page79/E4/1.jpg'/>
              <div>
                <b>1.</b>&ensp;st<u style='color:rgb(94 133 151)' >&ensp;ar&ensp;</u>       
              </div>
            </div>
            <div style='margin: 0px 30px;text-align:center'>
              <img  src='img/FriendsPlus/Page79/E4/2.jpg'/>
              <div>
                <b>2.</b>&ensp;c#n    
              </div>
            </div>
            <div  style='text-align:center'>
              <img  src='img/FriendsPlus/Page79/E4/3.jpg'/>
              <div>
                   <b>3.</b>&ensp;f#ty
              </div>
            </div>
        </div>
        <div style='display:flex'>
            <div style='text-align:center'>
              <img  src='img/FriendsPlus/Page79/E4/4.jpg'/>
              <div>
                <b>4.</b>&ensp;sc#f 
              </div>
            </div>
            <div style='margin: 0px 30px;text-align:center'>
              <img  src='img/FriendsPlus/Page79/E4/5.jpg'/>
              <div>
                   <b>5.</b>&ensp;h#n     
              </div>
            </div>
            <div  style='text-align:center'>
              <img  src='img/FriendsPlus/Page79/E4/6.jpg'/>
              <div>
                   <b>6.</b>&ensp;p#k
              </div>
            </div>
        </div>
        
        `,
        answer: ["or", "or", "ar", "or", "ar"],
      },
    ],
  },
  5: {
    unit: "Unit 10",
    id: "SB4-2023-U10-P79-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page79/E5/1.jpg" }]],
  },
};

export default json;
