import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 3",
    id: "SB4-2023-U3-P27-E1",
    audio: "Audios/Page27/audio-e1.mp3",
    video: "",
    component: T6,
    exerciseKey: "img/FriendsPlus/Page27/E1/Key/answerKey.png",
    maxLength: 7,
    textAlign: "center",
    inputSize: 45,
    selectStyle: {
      width: 85,
      height: 45,
      textAlign: "center",
      fontSize: 24,
    },
    selectOptionRandom: true,
    selectOptionValues: ["Yes", "No"],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and write Yes or No. <headphone name='40' src='Audios/Page27/tieude-e1.mp3'></headphone> <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/> <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questions: [
      {
        title: `
        <div style='position: relative;margin-left:20px'>
            <img style='width:26cm'  src='img/FriendsPlus/Page27/E1/1.jpg'/>
            <span style='position: absolute; top: 60px; left:380px'><select id=0></select></span>
            <span style='position: absolute; top: 60px; left: 820px'><select id=1></select></span>
            <span style='position: absolute; top: 135px; left: 310px'><select id=2></select></span>
            <span style='position: absolute; top: 135px; left: 845px'><select id=3></select></span>
            
          </div>
        `,
        answer: ["Yes", "No", "No", "Yes"],
      },
    ],
  },

  2: {
    unit: "Unit 3",
    id: "SB4-2023-U3-P27-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title: "Point, ask and answer.",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page27/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 3",
    id: "SB4-2023-U3-P27-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3 ",
        title: "What fruit does your mom or dad like?",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page27/E3/1.jpg" }]],
  },
  4: {
    unit: "Unit 3",
    id: "SB4-2023-U3-P27-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page27/E4/Key/answerKey.png",
    component: T6,
    // maxLength: 7,
    hideBtnFooter:true,
    inputSize: 160,
    textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: "4",
        title:
          "Write a menu with 6 types of food and drink. Use the example to help you. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='position: relative;margin-left:20px'>
            <img  src='img/FriendsPlus/Page27/E4/1.jpg'/>
            <span style='position: absolute; top: 90px; left:300px'><input height='40px' font-style='italic' font-weight='bold' width='500px' color='random' font-size='40px' id=0></input></span>
            
            <span style='position: absolute; top: 140px; left: 142px'>#:</span>
            <span style='position: absolute; top: 180px; left: 142px'>#:</span>
            <span style='position: absolute; top: 220px; left: 142px'>#:</span>

            <span style='position: absolute; top: 140px; left: 320px'>#</span>
            <span style='position: absolute; top: 180px; left: 320px'>#</span>
            <span style='position: absolute; top: 220px; left: 320px'>#</span>
            
            <span style='position: absolute; top: 140px; left: 575px'>#:</span>
            <span style='position: absolute; top: 180px; left: 575px'>#:</span>
            <span style='position: absolute; top: 220px; left: 575px'>#:</span>
           
            <span style='position: absolute; top: 140px; left: 755px'>#</span>
            <span style='position: absolute; top: 180px; left: 755px'>#</span>
            <span style='position: absolute; top: 220px; left: 755px'>#</span>
            
          </div>
        
        `,
        answer: ["", "", "", "", "", "", "", "", "", "", "", "", ""],
      },
    ],
  },
};

export default json;
