import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 9",
    id: "SB4-2023-U9-P67-E1",
    audio: "Audios/Page66/audio-e3.mp3",
    video: "Videos/Unit 09.mp4",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen to the story and repeat. <headphone name='107' src='Audios/Page66/tieude-e3.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page66/E3/1.jpg",
          audioUrl: "Audios/Page66/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E3/2.jpg",
          audioUrl: "Audios/Page66/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page66/E3/3.jpg",
          audioUrl: "Audios/Page66/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E3/4.jpg",
          audioUrl: "Audios/Page66/hinh4.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page66/E3/5.jpg",
          audioUrl: "Audios/Page66/hinh5.mp3",
        },
        {
          url: "img/FriendsPlus/Page66/E3/6.jpg",
          audioUrl: "Audios/Page66/hinh6.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 9",
    id: "SB4-2023-U9-P67-E2",
    audio: "Audios/Page67/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and say. <headphone name='108' src='Audios/Page67/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [{ url: "img/FriendsPlus/Page67/E2/1.jpg" }],
      [
        {
          url: "img/FriendsPlus/Page67/E2/2.jpg",
          audioUrl: "Audios/Page67/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page67/E2/3.jpg",
          audioUrl: "Audios/Page67/hinh2.mp3",
        },
        {
          url: "img/FriendsPlus/Page67/E2/4.jpg",
          audioUrl: "Audios/Page67/hinh3.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 9",
    id: "SB4-2023-U9-P67-E3",
    audio: "",
    video: "",
    titleQuestion: [
      {
        num: "3",
        title:
          "Look and say.  <img style='width:1cm' src='img/FriendsPlus/Page11/E4/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    component: UI,
    recorder: true,
    question: [],

    questionImage: [[{ url: "img/FriendsPlus/Page67/E3/1.jpg" }]],
  },
  4: {
    unit: "Unit 9",
    id: "SB4-2023-U9-P67-E4",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page67/E4/Key/answerKey.png",
    component: T6,
    checkUppercase: true,
    maxLength: 14,
    isHiddenCheck: true,
    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 176,
    titleQuestion: [
      {
        num: "4",
        title:
          "Write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex; width:70%'>

          <div>
            <img  src='img/FriendsPlus/Page67/E4/1.jpg'/>
            <div style='width:75%'>
              He<u style='color:rgb(25 181 239)' >&ensp;gets up&ensp;</u>at six o’clock in the morning.
            </div>
          </div>
          <div >
            <img  src='img/FriendsPlus/Page67/E4/2.jpg'/>
            <div style='width:90%'>
                He#at seven o’clock.
            </div>
          </div>
          <div >
            <img  src='img/FriendsPlus/Page67/E4/3.jpg'/>
            <div style='width:95%'>
              They#at eight o’clock.
            </div>
          </div>
        </div>
        

        
        `,
        answer: ["has breakfast", "go to school"],
      },
    ],
  },
  5: {
    unit: "Unit 9",
    id: "SB4-2023-U9-P67-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page67/E5/1.jpg" }]],
  },
};

export default json;
