import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 7",
    id: "SB4-2023-U7-P54-E1",
    audio: "Audios/Page54/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point and repeat. <headphone name='84' src='Audios/Page54/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page54/E1/1.jpg",
          audioUrl: "Audios/Page54/rainy.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E1/2.jpg",
          audioUrl: "Audios/Page54/windy.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E1/3.jpg",
          audioUrl: "Audios/Page54/hot.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E1/4.jpg",
          audioUrl: "Audios/Page54/cold.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E1/5.jpg",
          audioUrl: "Audios/Page54/snowy.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E1/6.jpg",
          audioUrl: "Audios/Page54/sunny.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 7",
    id: "SB4-2023-U7-P54-E2",
    audio: "Audios/Page54/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='85' src='Audios/Page54/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page54/E1/1.jpg",
          audioUrl: "Audios/Page54/rainy.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E1/2.jpg",
          audioUrl: "Audios/Page54/windy.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E1/3.jpg",
          audioUrl: "Audios/Page54/hot.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E1/4.jpg",
          audioUrl: "Audios/Page54/cold.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E1/5.jpg",
          audioUrl: "Audios/Page54/snowy.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E1/6.jpg",
          audioUrl: "Audios/Page54/sunny.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 7",
    id: "SB4-2023-U7-P54-E3",
    audio: "Audios/Page54/audio-e3.mp3",
    video: "Videos/Unit 07.mp4",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and read. <headphone name='86' src='Audios/Page54/tieude-e3.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page54/E3/1.jpg",
          audioUrl: "Audios/Page54/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E3/2.jpg",
          audioUrl: "Audios/Page54/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page54/E3/3.jpg",
          audioUrl: "Audios/Page54/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E3/4.jpg",
          audioUrl: "Audios/Page54/hinh4.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page54/E3/5.jpg",
          audioUrl: "Audios/Page54/hinh5.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E3/6.jpg",
          audioUrl: "Audios/Page54/hinh6.mp3",
        },
      ],
    ],
  },
};

export default json;
