import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";


const json = {
  1: {
    unit: "Unit 8",
    id: "SB4-2023-U8-P64-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page64/E1/Key/answerKey.png",
    component: T6,
    checkUppercase: true,
    maxLength: 5,
    textAlign: "center",
    textareaStyle: { width: 700 },
    inputSize: 100,
    titleQuestion: [
      {
        num: "1",
        title: "Look at the picture. What is the card for?",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page64/E1/1.jpg'/>
        <div style='text-align:center'>
            <textarea id='0' rows='2'></textarea >
        </div>
        
        
        `,
        answer: [""],
      },
    ],
  },
  2: {
    unit: "Unit 8",
    id: "SB4-2023-U8-P64-E2",
    audio: "Audios/Page64/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and read. <headphone name='103' src='Audios/Page64/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page64/E1/1.jpg" }]],
  },
  3: {
    unit: "Unit 8",
    id: "SB4-2023-U8-P64-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page64/E3/Key/answerKey.png",
    component: T6,
    maxLength: 1,
    textAlign: "center",
    inputSize: 40,
    titleQuestion: [
      {
        num: "3",
        title:
          "Read again and put the sentences in the correct order. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        
        
        <div style='display:flex' >
          <img   src='img/FriendsPlus/Page64/E1/1.jpg'/>
          
        </div>
        <div style='display:flex;line-height:50px'>
          <div style='margin-left: -50px;margin-right:50px'>
            <b>a.</b> Open the card. # <br> 
            <b>b.</b> Draw a picture on the front of the card. <u style='color:rgb(25 181 239)' >&ensp;1&ensp;</u> <br> 
            <b>c.</b> Give the card to your friend. #
          </div>
          <div>
            <b>d.</b> Write in the card. #<br>
            <b>e.</b> Draw a picture of you. #
          </div>
        </div>
           
            
            
        `,
        answer: ["2", "5", "4", "3"],
      },
    ],
  },
  4: {
    unit: "Unit 8",
    id: "SB4-2023-U8-P64-E4",
    audio: "",
    video: "",
    isHiddenCheck: true,
    exerciseKey: "",
    component: T6,
    // maxLength: 7,
    inputSize: 40,
    hideBtnFooter:true,
    textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: "4",
        title: "Do you like cards and presents? Why?",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div >
          <textarea id='0' rows='5'></textarea>
        </div>  
        
        `,
        answer: [""],
      },
    ],
  },
  5: {
    unit: "Unit 8",
    id: "SB4-2023-U8-P64-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page64/E5/1.jpg" }]],
  },
};

export default json;
