import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";

const json = {
  1: {
    unit: "Unit 11",
    id: "SB4-2023-U11-P87-E1",
    audio: "Audios/Page87/audio-e1.mp3",
    video: "",
    recorder: true,
    component: UI,
    // textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and point to the route. Where is Leo going? <headphone name='140' src='Audios/Page87/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page87/E2/1.jpg" }]],
  },

  2: {
    unit: "Unit 11",
    id: "SB4-2023-U11-P87-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen again and trace the route to Leo’s school with your finger. ",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page87/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 11",
    id: "SB4-2023-U11-P87-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3 ",
        title:
          "Give directions to a visitor at your school. Start in front of the school.",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page87/E3/1.jpg" }]],
  },
  4: {
    unit: "Unit 11",
    id: "SB4-2023-U11-P87-E4",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "4 ",
        title: "Choose a place at school and give directions to a friend.",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page87/E4/1.jpg" }]],
  },
  5: {
    unit: "Unit 11",
    id: "SB4-2023-U11-P87-E5",
    audio: "",
    video: "",
    // exerciseKey: "img/FriendsPlus/Page87/E4/Key/answerKey.png",
    component: T6,
    // maxLength: 7,
    textAlign: "center",
    inputSize: 120,
    textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: "5",
        title:
          "Write a party invite to a friend. Give directions. Look at page 86 to help you. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    hideBtnFooter: true,
    questions: [
      {
        title: `
        <div >
          <span style='color:rgb(20 178 243);font-size:italic'>Please come to my party.</span>
          <textarea id='0' rows='5'></textarea>
        </div>
        
        `,
        answer: ["name's", "eight", "fishing", "playing"],
      },
    ],
  },
  6: {
    unit: "Unit 11",
    id: "SB4-2023-U11-P87-E6",
    audio: "",
    video: "",
    isHiddenCheck: true,
    exerciseKey: "",
    component: T6,
    // maxLength: 7,
    inputSize: 40,
    textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: "6",
        title:
          "Underline the direction in your invite. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div >
          <textarea id='0' rows='5'></textarea>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
