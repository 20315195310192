import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    unit: "Unit 4",
    id: "SB4-2023-U4-P34-E1",
    audio: "Audios/Page34/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='51' src='Audios/Page34/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page34/E1/1.jpg",
          audioUrl: "Audios/Page34/school-yard.mp3",
        },
        {
          url: "img/FriendsPlus/Page34/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page34/E1/3.jpg",
          audioUrl: "Audios/Page34/art-room.mp3",
        },
        {
          url: "img/FriendsPlus/Page34/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page34/E1/5.jpg",
          audioUrl: "Audios/Page34/computer-room.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 4",
    id: "SB4-2023-U4-P34-E2",
    audio: "Audios/Page34/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and sing. <headphone name='52' src='Audios/Page34/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page34/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 4",
    id: "SB4-2023-U4-P34-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3",
        title: "Sing and do.",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page34/E2/1.jpg" }]],
  },
};

export default json;
