import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 7",
    id: "SB4-2023-U7-P55-E1",
    audio: "Audios/Page54/audio-e3.mp3",
    video: "Videos/Unit 07.mp4",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen to the story and repeat. <headphone name='86' src='Audios/Page54/tieude-e3.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page54/E3/1.jpg",
          audioUrl: "Audios/Page54/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E3/2.jpg",
          audioUrl: "Audios/Page54/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page54/E3/3.jpg",
          audioUrl: "Audios/Page54/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E3/4.jpg",
          audioUrl: "Audios/Page54/hinh4.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page54/E3/5.jpg",
          audioUrl: "Audios/Page54/hinh5.mp3",
        },
        {
          url: "img/FriendsPlus/Page54/E3/6.jpg",
          audioUrl: "Audios/Page54/hinh6.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 7",
    id: "SB4-2023-U7-P55-E2",
    audio: "Audios/Page55/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and say. <headphone name='87' src='Audios/Page55/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [{ url: "img/FriendsPlus/Page55/E2/1.jpg" }],
      [
        {
          url: "img/FriendsPlus/Page55/E2/2.jpg",
          audioUrl: "Audios/Page55/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page55/E2/3.jpg",
          audioUrl: "Audios/Page55/hinh2.mp3",
        },
        {
          url: "img/FriendsPlus/Page55/E2/4.jpg",
          audioUrl: "Audios/Page55/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page55/E2/5.jpg",
          audioUrl: "Audios/Page55/hinh4.mp3",
        },
      ],
      [{ url: "img/FriendsPlus/Page55/E2/6.jpg" }],
    ],
  },
  3: {
    unit: "Unit 7",
    id: "SB4-2023-U7-P55-E3",
    audio: "",
    video: "",
    titleQuestion: [
      {
        num: "3",
        title:
          "Look and say.  <img style='width:1cm' src='img/FriendsPlus/Page11/E4/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    component: UI,
    recorder: true,
    question: [],

    questionImage: [[{ url: "img/FriendsPlus/Page55/E3/1.jpg" }]],
  },
  4: {
    unit: "Unit 7",
    id: "SB4-2023-U7-P55-E4",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page55/E4/Key/answerKey.png",
    component: T6,
    checkUppercase: true,
    maxLength: 41,

    // textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 600,
    titleQuestion: [
      {
        num: "4",
        title:
          "Look and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img  src='img/FriendsPlus/Page55/E4/1.jpg'/>
        <div >
        
          <img  src='img/FriendsPlus/Page55/E4/2.jpg'/>
          <div style='line-height:50px'>
            <b>1.</b>&ensp;Open<u style='color:rgb(25 181 239)' >&ensp;the window because it’s hot&ensp;</u>. <br>
            <b>2.</b>&ensp;Don’t<input id='0' width='540px'></input><br>
            <b>3.</b>&ensp;#<br>
            <b>4.</b>&ensp;#<br>
          </div>
        </div>
        

        
        `,
        answer: [
          "wear a coat because it's sunny",
          "Don't open the window because it's rainy",
          "Wear a hat because it's cold",
        ],
        notSymbol:true,
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "SB4-2023-U7-P55-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page55/E5/1.jpg" }]],
  },
};

export default json;
