import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 5",
    id: "SB4-2023-U5-P39-E1",
    audio: "Audios/Page38/audio-e3.mp3",
    video: "Videos/Unit 02.mp4",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen to the story and repeat. <headphone name='49' src='Audios/Page38/tieude-e3.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page38/E3/1.jpg",
          audioUrl: "Audios/Page38/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page38/E3/2.jpg",
          audioUrl: "Audios/Page38/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page38/E3/3.jpg",
          audioUrl: "Audios/Page38/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page38/E3/4.jpg",
          audioUrl: "Audios/Page38/hinh4.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 5",
    id: "SB4-2023-U5-P39-E2",
    audio: "Audios/Page39/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and say. <headphone name='61' src='Audios/Page39/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        { url: "img/FriendsPlus/Page39/E2/1.jpg" },],[
        { url: "img/FriendsPlus/Page39/E2/2.jpg" },
        { url: "img/FriendsPlus/Page39/E2/3.jpg",audioUrl:"Audios/Page39/hinh1.mp3" },
        { url: "img/FriendsPlus/Page39/E2/4.jpg" },],[
        { url: "img/FriendsPlus/Page39/E2/5.jpg" },
        { url: "img/FriendsPlus/Page39/E2/6.jpg",audioUrl:"Audios/Page39/hinh2.mp3" },
        { url: "img/FriendsPlus/Page39/E2/7.jpg" },],[
        { url: "img/FriendsPlus/Page39/E2/8.jpg" },
        { url: "img/FriendsPlus/Page39/E2/9.jpg",audioUrl:"Audios/Page39/hinh3.mp3" },
        { url: "img/FriendsPlus/Page39/E2/10.jpg" },],[
        { url: "img/FriendsPlus/Page39/E2/11.jpg" },
    ]
    ],
  },
  3: {
    unit: "Unit 5",
    id: "SB4-2023-U5-P39-E3",
    audio: "",
    video: "",
    component: T6,
    recorder: true,
    inputSize: 50,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page39/E3/Key/answerKey.png",

    maxLength: 1,
    titleQuestion: [
      {
        num: "3",
        title:
          "Read and match. Write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questions: [
      {
        title: `
        <div style='display:flex'>
				  <img  src='img/FriendsPlus/Page39/E3/1.jpg'/>
          <div style='line-height:55px'>
            <b>1</b>&ensp;She’s going on a boat. <u style='color:rgb(25 181 239)' >&ensp;e&ensp;</u><br>
            <b>2</b>&ensp;They’re snorkeling. #<br>
            <b>3</b>&ensp;She’s collecting shells. #<br>
            <b>4</b>&ensp;He’s playing with a ball. #<br>
            <b>5</b>&ensp;They’re surfing.# 
          </div>
        </div>
				


        
        `,
        answer: ["a", "b", "d", "c"],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "SB4-2023-U5-P39-E4",
    audio: "",
    exerciseKey: "img/FriendsPlus/Page39/E4/Key/answerKey.png",
    component: T6,
    checkUppercase: true,
    maxLength: 20,

    textAlign: "center",
    stylesTextInput: {
      borderBottom: "dash",
    },
    inputSize: 100,
    titleQuestion: [
      {
        num: "4",
        title:
          "Write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex;'>
          <div style='margin-right:3cm'>
				    <img  src='img/FriendsPlus/Page39/E4/1.jpg'/>
             <div style='width:10cm'>
                He <u style='color:rgb(25 181 239)' >&ensp;isn’t&ensp;</u> snorkeling.<br> He
                <u style='color:rgb(25 181 239)' >&ensp;’s eating&ensp;</u>. 
              </div>
				    <img  src='img/FriendsPlus/Page39/E4/2.jpg'/>
             <div style='width:10cm '>
                They#collecting shells.<br> They <input id='1' width='250px'></input>.
             </div> 
          </div> 

          <div>
				    <img  src='img/FriendsPlus/Page39/E4/3.jpg'/>
             <div style='width:10cm'>
                We#reading.<br> We<input id='3' width='250px'></input>
             </div> 
				    <img  src='img/FriendsPlus/Page39/E4/4.jpg'/>
             <div style='width:10cm'>
                She#playing with a ball.<br> She <input id='5' width='250px'></input>
             </div> 
          </div>
        </div>


        
        `,
        answer: [
          "aren't",
          "'re taking photos",
          "aren't",
          "'re going on a boat",
          "isn't",
          "'s reading a book",
        ],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "SB4-2023-U5-P39-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page39/E5/1.jpg" }]],
  },
};

export default json;
