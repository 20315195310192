import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 4",
    id: "SB4-2023-U4-P32-E1",
    audio: "Audios/Page32/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point and repeat. <headphone name='47' src='Audios/Page32/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page32/E1/1.jpg",
          audioUrl: "Audios/Page32/art.mp3",
        },
        { url: "img/FriendsPlus/Page32/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page32/E1/3.jpg",
          audioUrl: "Audios/Page32/math.mp3",
        },
        { url: "img/FriendsPlus/Page32/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page32/E1/5.jpg",
          audioUrl: "Audios/Page32/English.mp3",
        },
        { url: "img/FriendsPlus/Page32/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page32/E1/7.jpg",
          audioUrl: "Audios/Page32/pe.mp3",
        },
        {
          url: "img/FriendsPlus/Page32/E1/8.jpg",
        },
        {
          url: "img/FriendsPlus/Page32/E1/9.jpg",
          audioUrl: "Audios/Page32/vietnamese.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 4",
    id: "SB4-2023-U4-P32-E2",
    audio: "Audios/Page32/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='48' src='Audios/Page32/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page32/E1/1.jpg",
          audioUrl: "Audios/Page32/art.mp3",
        },
        { url: "img/FriendsPlus/Page32/E1/2.jpg" },
        {
          url: "img/FriendsPlus/Page32/E1/3.jpg",
          audioUrl: "Audios/Page32/math.mp3",
        },
        { url: "img/FriendsPlus/Page32/E1/4.jpg" },
        {
          url: "img/FriendsPlus/Page32/E1/5.jpg",
          audioUrl: "Audios/Page32/english.mp3",
        },
        { url: "img/FriendsPlus/Page32/E1/6.jpg" },
        {
          url: "img/FriendsPlus/Page32/E1/7.jpg",
          audioUrl: "Audios/Page32/pe.mp3",
        },
        {
          url: "img/FriendsPlus/Page32/E1/8.jpg",
        },
        {
          url: "img/FriendsPlus/Page32/E1/9.jpg",
          audioUrl: "Audios/Page32/vietnamese.mp3",
        },
      ],
    ],
  },
  3: {
    unit: "Unit 4",
    id: "SB4-2023-U4-P32-E3",
    audio: "Audios/Page32/audio-e3.mp3",
    video: "Videos/Unit 04.mp4",
    component: UI,
    recorder: true,
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen and read. <headphone name='49' src='Audios/Page32/tieude-e3.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page32/E3/1.jpg",
          audioUrl: "Audios/Page32/hinh1.mp3",
        },
        {
          url: "img/FriendsPlus/Page32/E3/2.jpg",
          audioUrl: "Audios/Page32/hinh2.mp3",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page32/E3/3.jpg",
          audioUrl: "Audios/Page32/hinh3.mp3",
        },
        {
          url: "img/FriendsPlus/Page32/E3/4.jpg",
          audioUrl: "Audios/Page32/hinh4.mp3",
        },
      ],
      [
        {
          audioUrl: "Audios/Page32/hinh5.mp3",
          url: "img/FriendsPlus/Page32/E3/5.jpg",
        },
        {
          audioUrl: "Audios/Page32/hinh6.mp3",
          url: "img/FriendsPlus/Page32/E3/6.jpg",
        },
      ],
    ],
  },
};

export default json;
