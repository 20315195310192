import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 11",
    id: "SB4-2023-U11-P85-E1",
    audio: "Audios/Page85/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='136' src='Audios/Page85/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page85/E1/1.jpg",
        },
      ],
      [
        {
          url: "img/FriendsPlus/Page85/E1/2.jpg",
          audioUrl: "Audios/Page85/germ.mp3",
        },
        {
          url: "img/FriendsPlus/Page85/E1/3.jpg",
          audioUrl: "Audios/Page85/fern.mp3",
        },
        {
          url: "img/FriendsPlus/Page85/E1/4.jpg",
          audioUrl: "Audios/Page85/girl.mp3",
        },
        {
          url: "img/FriendsPlus/Page85/E1/5.jpg",
          audioUrl: "Audios/Page85/bird.mp3",
        },
        {
          url: "img/FriendsPlus/Page85/E1/6.jpg",
          audioUrl: "Audios/Page85/nurse.mp3",
        },
        {
          url: "img/FriendsPlus/Page85/E1/7.jpg",
          audioUrl: "Audios/Page85/hurt.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 11",
    id: "SB4-2023-U11-P85-E2",
    audio: "Audios/Page85/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and sing. <headphone name='137' src='Audios/Page85/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page85/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 11",
    id: "SB4-2023-U11-P85-E3",
    audio: "",
    video: "",
    isHiddenCheck: true,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page85/E3/Key/answerKey.png",
    component: T6,
    maxLength: 5,
    inputSize: 115,
    titleQuestion: [
      {
        num: "3",
        title:
          "Read the chant again. Say the words with er, ir, and ur. Write. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img  src='img/FriendsPlus/Page85/E2/1.jpg'/>
        <div style='margin-left:60px'>#,#,#,#,#,#,#</div>
        
        `,
        answer: ["girl", "hurts", "nurse", "purse", "bird", "fern", "germs"],
      },
    ],
  },
  4: {
    unit: "Unit 11",
    id: "SB4-2023-U11-P85-E4",
    audio: "Audios/Page85/audio-e4.mp3",
    video: "",
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page85/E4/Key/answerKey.png",
    component: T6,
    textAlign: "center",
    maxLength: 2,
    inputSize: 45,
    titleQuestion: [
      {
        num: "4",
        title:
          "Listen and complete the words er, ir, or ur. <headphone name='138' src='Audios/Page85/tieude-e4.mp3'></headphone> <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex;'>
        <div style='text-align:center'>
          <img  src='img/FriendsPlus/Page85/E4/1.jpg'/>
          <div>
            n<u style='color:rgb(94 133 151)' >&ensp;ur&ensp;</u>se      
          </div>
        </div>
        <div style='margin: 0px 30px;text-align:center'>
          <img  src='img/FriendsPlus/Page85/E4/2.jpg'/>
          <div>
            sk#t    
          </div>
        </div>
        <div  style='text-align:center'>
          <img  src='img/FriendsPlus/Page85/E4/3.jpg'/>
          <div>
               f#n
          </div>
        </div>
    </div>
    <div style='display:flex'>
        <div style='text-align:center'>
          <img  src='img/FriendsPlus/Page85/E4/4.jpg'/>
          <div>
            p#se
          </div>
        </div>
        <div style='margin: 0px 30px;text-align:center'>
          <img  src='img/FriendsPlus/Page85/E4/5.jpg'/>
          <div>
               sh#t     
          </div>
        </div>
        <div  style='text-align:center'>
          <img  src='img/FriendsPlus/Page85/E4/6.jpg'/>
          <div>
               g#ms
          </div>
        </div>
    </div>
        
        `,
        answer: ["ir", "er", "ur", "ir", "er"],
      },
    ],
  },
  5: {
    unit: "Unit 11",
    id: "SB4-2023-U11-P85-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page85/E5/1.jpg" }]],
  },
};

export default json;
