import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 6",
    id: "SB4-2023-U6-P48-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page48/E1/Key/answerKey.png",
    component: T6,
    checkUppercase: true,
    // maxLength: 10,
    // textAlign: "center",
    inputSize: 160,
    titleQuestion: [
      {
        num: "1",
        title:
          "Look at the picture. Where are the children? What animals do you see?",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page48/E1/1.jpg'/>
        <div>
          Where are the children?<br><input id='0' width='300px'></input><br>
          What animals do you see?<br>#,#,#,#,<br>#,#,#
        </div>
        
        
        `,
        answer: [
          "They’re at the zoo.",
          "camel / penguin / lizards / monkeys / lions / zebra / kangaroo",
          "camel / penguin / lizards / monkeys / lions / zebra / kangaroo",
          "camel / penguin / lizards / monkeys / lions / zebra / kangaroo",
          "camel / penguin / lizards / monkeys / lions / zebra / kangaroo",
          "camel / penguin / lizards / monkeys / lions / zebra / kangaroo",
          "camel / penguin / lizards / monkeys / lions / zebra / kangaroo",
          "camel / penguin / lizards / monkeys / lions / zebra / kangaroo",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 6",
    id: "SB4-2023-U6-P48-E2",
    audio: "Audios/Page48/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and read. <headphone name='77' src='Audios/Page48/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page48/E1/1.jpg" }]],
  },
  3: {
    unit: "Unit 6",
    id: "SB4-2023-U6-P48-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page48/E3/Key/answerKey.png",
    component: T6,
    maxLength: 21,
    textAlign: "center",
    inputSize: 160,
    titleQuestion: [
      {
        num: "3",
        title:
          "Read again and write. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img  src='img/FriendsPlus/Page48/E1/1.jpg'/>
        <div style='line-height:50px' >
          <b>1</b>.&ensp;The school trip is on<u style='color:rgb(25 181 239)' >&ensp;Wednesday&ensp;</u>.<br>
          <b>2</b>.&ensp;The trip is to a#.<br>
          <b>3</b>.&ensp;There are lizards,#,and crocodiles.<br>
          <b>4</b>.&ensp;Bring a<input id='2' width='300px'></input>.<br>
          <b>5</b>.&ensp;Wear a#.<br>

        </div>
        `,
        answer: ["zoo", "kangaroos", "lunchbox and a drink", "sun hat"],
      },
    ],
  },
  4: {
    unit: "Unit 6",
    id: "SB4-2023-U6-P48-E4",
    audio: "",
    video: "",
    isHiddenCheck: true,
    exerciseKey: "",
    component: T6,
    // maxLength: 7,
    inputSize: 40,
    hideBtnFooter:true,
    
    textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: "4",
        title: "Where do you go on school trip?",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div >
          <textarea id='0' rows='5'></textarea>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
  5: {
    unit: "Unit 6",
    id: "SB4-2023-U6-P48-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page48/E5/1.jpg" }]],
  },
};

export default json;
