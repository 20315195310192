import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";

const json = {
  1: {
    unit: "Unit 1",
    id: "SB4-demo-U1-P13-E1",
    audio: "Audios/Page13/E1/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='14' src='Audios/Page13/E1/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page13/E1/1.jpg",
          audioUrl: "Audios/Page13/E1/doctor.mp3",
        },
        {
          url: "img/FriendsPlus/Page13/E1/2.jpg",
        },
        {
          url: "img/FriendsPlus/Page13/E1/3.jpg",
          audioUrl: "Audios/Page13/E1/pilot.mp3",
        },
        {
          url: "img/FriendsPlus/Page13/E1/4.jpg",
        },
        {
          url: "img/FriendsPlus/Page13/E1/5.jpg",
          audioUrl: "Audios/Page13/E1/firefighter.mp3",
        },
        {
          url: "img/FriendsPlus/Page13/E1/6.jpg",
        },
        {
          url: "img/FriendsPlus/Page13/E1/7.jpg",
          audioUrl: "Audios/Page13/E1/office-worker.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 1",
    id: "SB4-demo-U1-P13-E2",
    audio: "Audios/Page13/E2/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen, chant, and clap at the big circles. <headphone name='15' src='Audios/Page13/E2/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page13/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 1",
    id: "SB4-demo-U1-P13-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3",
        title:
          "Listen, chant the chant again. Say the job words.<img style='width:1cm' src='img/FriendsPlus/Page11/E4/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page13/E2/1.jpg" }]],
  },
  4: {
    unit: "Unit 1",
    id: "SB4-demo-U1-P13-E4",
    audio: "Audios/Page13/E4/audio-e4.mp3",
    video: "",
    exerciseKey: "img/FriendsPlus/Page13/E4/Key/answerKey.png",
    component: DesignUnderLine,
    // recorder: true,
    question: [],
    isHiddenCheck: true,
    totalInput: 7,
    titleQuestion: [
      {
        num: "4",
        title:
          "Listen and underline the letters with schwa. <headphone name='16' src='Audios/Page13/E4/tieude-e4.mp3'></headphone> <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [{ url: "img/FriendsPlus/Page13/E4/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page13/E4/2.jpg" },
        { url: "img/FriendsPlus/Page13/E4/3.jpg", input: 1 },
        { url: "img/FriendsPlus/Page13/E4/4.jpg", input: 1, isCorrect: true },
        { url: "img/FriendsPlus/Page13/E4/5.jpg" },
        { url: "img/FriendsPlus/Page13/E4/6.jpg", input: 2 },
        { url: "img/FriendsPlus/Page13/E4/7.jpg", input: 2, isCorrect: true },
        { url: "img/FriendsPlus/Page13/E4/8.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page13/E4/9.jpg" }],
      [
        { url: "img/FriendsPlus/Page13/E4/10.jpg" },
        { url: "img/FriendsPlus/Page13/E4/11.jpg", input: 3 },
        { url: "img/FriendsPlus/Page13/E4/12.jpg" },
        { url: "img/FriendsPlus/Page13/E4/13.jpg", input: 3, isCorrect: true },
        { url: "img/FriendsPlus/Page13/E4/14.jpg" },
        { url: "img/FriendsPlus/Page13/E4/15.jpg", input: 4 },
        { url: "img/FriendsPlus/Page13/E4/16.jpg" },
        { url: "img/FriendsPlus/Page13/E4/17.jpg", input: 4, isCorrect: true },
        { url: "img/FriendsPlus/Page13/E4/18.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page13/E4/19.jpg" }],
      [
        { url: "img/FriendsPlus/Page13/E4/20.jpg" },
        { url: "img/FriendsPlus/Page13/E4/21.jpg", input: 5 },
        { url: "img/FriendsPlus/Page13/E4/22.jpg" },
        { url: "img/FriendsPlus/Page13/E4/23.jpg", input: 5 },
        { url: "img/FriendsPlus/Page13/E4/24.jpg" },
        { url: "img/FriendsPlus/Page13/E4/25.jpg", input: 5 },
        { url: "img/FriendsPlus/Page13/E4/26.jpg" },
        { url: "img/FriendsPlus/Page13/E4/27.jpg", input: 5, isCorrect: true },
        { url: "img/FriendsPlus/Page13/E4/28.jpg" },
        { url: "img/FriendsPlus/Page13/E4/29.jpg", input: 6, isCorrect: true },
        { url: "img/FriendsPlus/Page13/E4/30.jpg", input: 6 },
        { url: "img/FriendsPlus/Page13/E4/31.jpg" },
        { url: "img/FriendsPlus/Page13/E4/32.jpg", input: 7 },
        { url: "img/FriendsPlus/Page13/E4/33.jpg" },
        { url: "img/FriendsPlus/Page13/E4/34.jpg", input: 7, isCorrect: true },
        { url: "img/FriendsPlus/Page13/E4/35.jpg" },
        { url: "img/FriendsPlus/Page13/E4/36.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page13/E4/37.jpg" }],
    ],
  },
  5: {
    unit: "Unit 1",
    id: "SB4-demo-U1-P13-E5",
    // audio: "img/FriendsPlus/Page40/Audio/audio-e2-p40.mp3",
    video: "",
    component: UI,
    recorder: true,
    questionImage: [[{ url: "img/FriendsPlus/Page13/E5/1.jpg" }]],
  },
};

export default json;
