import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 9",
    id: "SB4-2023-U9-P70-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page70/E1/Key/answerKey.png",
    component: T6,
    checkUppercase: true,
    // maxLength: 10,
    textAlign: "center",
    inputSize: 200,
    titleQuestion: [
      {
        num: "1",
        title: "Look at the pictures. Say what the girl and her mom do.",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page70/E1/1.jpg'/>
        <div>
        <u style='font-style:italic; font-weight:bold'>ANSWERS</u> <br>
          Linh:<input id='0' width='110px'></input>,#,#,#,#<br>
          Linh’s mom:<input id='5' width='170px'></input>,<input id='6' width='100px'></input>,#<b> <br>
          Linh and her mom: <input id='8' width='370px'></input>
        </div>
        
        
        `,
        answer: [
          "gets up",
          "has breakfast",
          "takes her bag",
          "walks to school",
          "goes home",
          "goes to work",
          "works",
          "goes home",
          "cook dinner and eat together",
        ],
      },
    ],
  },
  2: {
    unit: "Unit 9",
    id: "SB4-2023-U9-P70-E2",
    audio: "Audios/Page70/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and read. <headphone name='113' src='Audios/Page70/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page70/E1/1.jpg" }]],
  },
  3: {
    unit: "Unit 9",
    id: "SB4-2023-U9-P70-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page70/E3/Key/answerKey.png",
    component: T6,
    maxLength: 1,
    textAlign: "center",
    inputSize: 50,
    titleQuestion: [
      {
        num: "3",
        title:
          "Read again and write T (true) or F (false). <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img  src='img/FriendsPlus/Page70/E1/1.jpg'/>
        <div  >
          <b>1</b>&ensp;Linh gets up at six o’clock in the morning. <u style='color:rgb(25 181 239)' >&ensp;T&ensp;</u><br>
          <b>2</b>&ensp;She walks to school at seven o’clock in the morning. #<br>
          <b>3</b>&ensp;Linh’s mom goes to work at six forty-five in the morning. #<br>
          <b>4</b>&ensp;Linh’s mom goes home at four thirty in the afternoon. #<br>
          <b>5</b>&ensp;Linh and her mom have dinner together. #

        </div>
        `,
        answer: ["T", "T", "F", "T"],
      },
    ],
  },
  4: {
    unit: "Unit 9",
    id: "SB4-2023-U9-P70-E4",
    audio: "",
    video: "",
    isHiddenCheck: true,
    exerciseKey: "",
    component: T6,
    // maxLength: 7,
    inputSize: 40,
    textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: "4",
        title: "What’ your favorite room in your school? Why?",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div >
          <textarea id='0' rows='5'></textarea>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
  5: {
    unit: "Unit 9",
    id: "SB4-2023-U9-P70-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page70/E5/1.jpg" }]],
  },
};

export default json;
