import DesignTypeIn from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Match_Write from "../../components/ExcerciseTypes/Match_Write";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  7: {
    // Exercise num
    unit: "Review 3",
    id: "SB4-2023-R3-P73-E7",
    audio: "",
    component: T6,
    maxLength: 4,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page73/E7/Key/answerKey.png",

    inputSize: 80,
    titleQuestion: [
      {
        num: "7",
        title:
          "Write. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page73/E7/1.jpg'>
        <div style='display:flex; justify-content: space-between;line-height:50px'>
            <div>
              <b>1.</b>&ensp;fea#er<br>
              <b>2.</b>&ensp;ri#<br>
              <b>3.</b>&ensp;explo<input id='2' width='80px'></input>
            </div>
            <div>
              <b>4.</b>&ensp;s#p<br>
              <b>5.</b>&ensp;trea<input id='4' width='80px'></input><br>
              <b>6.</b>&ensp;f#t
            </div>
        </div>
        `,
        answer: ["th", "ng", "sion", "ou", "sure", "oo"],
      },
    ],
  },

  4: {
    unit: "Review 3",
    id: "SB4-2023-R3-P73-E4",
    audio: "",
    video: "",
    titleQuestion: [
      {
        num: "4",
        title:
          "Read again and write T (true) or F(false). <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    exerciseKey: "img/FriendsPlus/Page73/E4/Key/answerKey.png",

    component: T6,
    textAlign: "center",
    inputSize: 50,
    maxLength: 1,

    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='line-height:50px'>
            <b>1.</b>&ensp;Lily gets up at seven o’clock. <u style='color:rgb(25 181 239)' >&ensp;F&ensp;</u><br>
            <b>2.</b>&ensp;She has breakfast with her dad. #<br>
            <b>3.</b>&ensp;She has eggs for breakfast. #<br>
            <b>4.</b>&ensp;She goes to school at 7:25. #<br>
            <b>5.</b>&ensp;She has noodles for lunch. #<br>
            <b>6.</b>&ensp;She goes to bed at nine o’clock. #<br>
        </div>
        `,
        answer: ["F", "T", "F", "T", "T"],
      },
    ],
  },
  5: {
    unit: "Review 3",
    id: "SB4-2023-R3-P73-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    titleQuestion: [
      {
        num: "5",
        title:
          "Look and say.  <img style='width:1cm' src='img/FriendsPlus/Page11/E4/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page73/E5/1.jpg" }]],
  },
  6: {
    unit: "Review 3",
    id: "SB4-2023-R3-P73-E4",
    audio: "",
    video: "",
    isHiddenCheck: true,
    exerciseKey: "",
    component: T6,
    // maxLength: 7,
    inputSize: 40,
    textareaStyle: { width: 800 },
    hideBtnFooter:true,

    titleQuestion: [
      {
        num: "6",
        title:
          "What do you do in a day? Write 20-30 words. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <span style='font-style:italic;color:rgb(25 181 239)' >&ensp;I get up at six fifteen. I have breakfast with my ... &ensp;</span>
        <div >
          <textarea id='0' rows='5'></textarea>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
};
export default json;
