import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 5",
    id: "SB4-2023-U5-P43-E1",
    audio: "Audios/Page43/audio-e1.mp3",
    video: "",
    component: D1,
    maxLength: 1,
    fontSize: 27,
    exerciseKey: "img/FriendsPlus/Page43/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and write the numbers. <headphone name='68' src='Audios/Page43/tieude-e1.mp3'></headphone> <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [{ url: "img/FriendsPlus/Page43/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page43/E1/2.jpg" },
        { url: "img/FriendsPlus/Page43/E1/3.jpg", input: true, answer: "5" },
        { url: "img/FriendsPlus/Page43/E1/4.jpg" },
        { url: "img/FriendsPlus/Page43/E1/5.jpg", input: true, answer: "4" },
        { url: "img/FriendsPlus/Page43/E1/6.jpg" },
        { url: "img/FriendsPlus/Page43/E1/7.jpg", input: true, answer: "3" },
      ],
      [{ url: "img/FriendsPlus/Page43/E1/8.jpg" }],
      [
        { url: "img/FriendsPlus/Page43/E1/9.jpg" },
        { url: "img/FriendsPlus/Page43/E1/10.jpg", input: true, answer: "2" },
        { url: "img/FriendsPlus/Page43/E1/11.jpg" },
      ],
    ],
  },

  2: {
    unit: "Unit 5",
    id: "SB4-2023-U5-P43-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title: "Look at the words in the box. Ask and answer.",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page43/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 5",
    id: "SB4-2023-U5-P43-E3",
    audio: "",
    video: "",
    component: T6,
    maxLength: 7,
    inputSize: 40,
    textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: "3",
        title:
          "Draw a picture of you and your family at the beach. What are they doing?",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div >
          <textarea id='0' rows='5'></textarea>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },
  4: {
    unit: "Unit 5",
    id: "SB4-2023-U5-P43-E4",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page43/E4/Key/answerKey.png",
    component: T6,
    inputSize: 40,
    textAlign: "center",
    maxLength: 1,
    stylesTextInput: {
      height: 40,
      borderRadius: 10,
      border: "2px solid",
    },
    // maxLength: ,
    titleQuestion: [
      {
        num: "4",
        title:
          "Number the sentences in order. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questions: [
      {
        title: `
        <div  style='line-height:50px'>
          <b>a.</b>&ensp;I’m having fun at the beach.#<br>
          <b>b.</b>&ensp;Dear Binh, <span style='color:rgb(25 181 239);border:2px solid black; border-radius:10px; text-align:center;padding:5px 12px '>1</span ><br>
          <b>c.</b>&ensp;See you soon! #<br>
          <b>d.</b>&ensp;Tuan Anh. #<br>
          <b>e.</b>&ensp;I’m collecting shells and sitting on the sand. My parent are swimming. #<br>
          <b>f.</b>&ensp;The beach is beautiful. #<br>
        </div>
        
        `,
        answer: ["2", "5", "6", "4", "3"],
      },
    ],
  },
  5: {
    unit: "Unit 5",
    id: "SB4-2023-U5-P42-E5",
    audio: "",
    video: "",
    recorder: true,
    component: T6,
    textareaStyle: { width: 500 },
    titleQuestion: [
      {
        num: "5",
        title:
          "You are on vacation. Write a postcard to a friend. Look at page 42 to help you.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        
          <div style='position: relative;margin-left:20px'>
              <img style='margin-left:100px' src='img/FriendsPlus/Page42/E1/1.jpg'/>
            <span style='position: absolute; top: 420px; left:-80px'>
              <textarea id='0' rows='4'></textarea>
            
            </span>
            
            
          </div>
        `,
        answer: [""],
      },
    ],
  },
};

export default json;
