import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
const json = {
  1: {
    unit: "Starter",
    id: "SB4-2023-Starter-P7-E1",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Point, ask, and answer. <img style='width:1cm' src='img/FriendsPlus/Page11/E4/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page7/E1/1.jpg",
        },
      ],
    ],
  },
  2: {
    unit: "Starter",
    id: "SB4-2023-Starter-P7-E2",
    audio: "Audios/Page7/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and point. Ask and answer. <headphone name='06' src='Audios/Page7/tieude-e2.mp3'></headphone> <img style='width:1cm' src='img/FriendsPlus/Page11/E4/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page7/E2/1.jpg",
        },
      ],
    ],
  },
};

export default json;
