import DesignTypeIn from "../../components/ExcerciseTypes/Design/TypeInNoSubmit";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import Match_Write from "../../components/ExcerciseTypes/Match_Write";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";

const json = {
  3: {
    // Exercise num
    unit: "Review 2",
    id: "SB4-2023-R2-P51-E3",
    audio: "",
    component: T6,
    // maxLength: 1,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page51/E3/Key/answerKey.png",

    inputSize: 50,
    titleQuestion: [
      {
        num: "3",
        title:
          "Look and write. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div style='display:flex;margin-bottom:20px'>
        <div >
        <img  src='img/FriendsPlus/Page51/E3/1.jpg'/><br>
            <b>1</b>.&ensp;Ma<u style='color:rgb(25 181 239)' >&ensp;th&ensp;</u>

          </div>
          <div >
          <img  src='img/FriendsPlus/Page51/E3/2.jpg'/><br>
            <b>2</b>.&ensp;P#
          </div>
          <div >
          <img  src='img/FriendsPlus/Page51/E3/3.jpg'/><br>
            <b>3</b>.&ensp;V<input id='1' width='160px'></input>
          </div>
          <div >
          <img  src='img/FriendsPlus/Page51/E3/4.jpg'/><br>
            <b>4</b>.&ensp;E<input id='2' width='100px'></input>
          </div>
        </div>

        <div style='display:flex'>
        <div >
        <img  src='img/FriendsPlus/Page51/E3/5.jpg'/><br>
            <b>5</b>.&ensp;a#
          </div>
          <div  >
          <img  src='img/FriendsPlus/Page51/E3/6.jpg'/><br>
            <b>6</b>.&ensp;c<input id='4' width='130px'></input><br> r<input id='5' width='80px'></input>
          </div>
          <div >
          <img  src='img/FriendsPlus/Page51/E3/7.jpg'/><br>
            <b>7</b>.&ensp;a#r<input id='7' width='80px'></input>
          </div>
          <div >
          <img  src='img/FriendsPlus/Page51/E3/8.jpg'/><br>
            <b>8</b>.&ensp;s<input id='8' width='145px'></input>
          </div>
        </div>
        `,
        answer: [
          ".E",
          "ietnamese",
          "nglish",
          "rt",
          "omputer",
          "oom",
          "rt",
          "oom",
          "choolyard",
        ],
      },
    ],
  },
  // 4: {
  //   unit: "Review 2",
  //   id: "SB4-2023-R2-P51-E1",
  //   audio: "",
  //   video: "",
  //   component: MatchDots,
  //   exerciseKey: "img/FriendsPlus/Page51/E4/Key/answerKey.png",
  //   stylesTextInput: { borderBottom: "dotted" },
  //   inputSize: 700,
  //   maxLength: 200,
  //   checkUppercase: true,
  //   // exerciseKey: "img/FriendsPlus/Page32/E8/Key/answerKey.png",
  //   titleQuestion: [
  //     {
  //       num: "",
  //       title:
  //         "<img src='img/FriendsPlus/Page50/E4/15.jpg' /> <br> <img src='img/FriendsPlus/Page50/E4/1.jpg' /> ",
  //       color: "",
  //     },
  //   ],
  //   question: {
  //     DrawLines: {
  //       multipleLine: false,
  //       boxMatch: [
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "41px",
  //             left: "350px",
  //             width: "30px",
  //             height: "30px",
  //             borderRadius: "50%",
  //             border: "1px solid #2eb6e1",
  //             background: "white",
  //           },
  //         }, // 0
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "41px",
  //             left: "580px",
  //             width: "30px",
  //             height: "30px",
  //             borderRadius: "50%",
  //             border: "1px solid #2eb6e1",
  //             background: "white",
  //           },
  //         }, // 1
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "105px",
  //             left: "350px",
  //             width: "30px",
  //             height: "30px",
  //             borderRadius: "50%",
  //             border: "1px solid #2eb6e1",
  //             background: "white",
  //           },
  //         }, // 2
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "105px",
  //             left: "580px",
  //             width: "30px",
  //             height: "30px",
  //             borderRadius: "50%",
  //             border: "1px solid #2eb6e1",
  //             background: "white",
  //           },
  //         }, // 3
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "171px",
  //             left: "350px",
  //             width: "30px",
  //             height: "30px",
  //             borderRadius: "50%",
  //             border: "1px solid #2eb6e1",
  //             background: "white",
  //           },
  //         }, // 4
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "171px",
  //             left: "580px",
  //             width: "30px",
  //             height: "30px",
  //             borderRadius: "50%",
  //             border: "1px solid #2eb6e1",
  //             background: "white",
  //           },
  //         }, // 5
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "235px",
  //             left: "350px",
  //             width: "30px",
  //             height: "30px",
  //             borderRadius: "50%",
  //             border: "1px solid #2eb6e1",
  //             background: "white",
  //           },
  //         }, // 6
  //         {
  //           boxMatchStyle: {
  //             position: "absolute",
  //             top: "235px",
  //             left: "580px",
  //             width: "30px",
  //             height: "30px",
  //             borderRadius: "50%",
  //             border: "1px solid #2eb6e1",
  //             background: "white",
  //           },
  //         }, // 7
  //       ],
  //       answers: ["0-5", "1-4", "2-7", "3-6"],
  //       initialValue: [],
  //     },
  //     Layout: `
  //       <div style="font-size: 30px;">

  //         <div style='display: flex;padding-top: 5mm'>
  //           <div ><b>1</b> I don’t like fish.</div>
  // 					<div><input id='5' type= 'boxMatch' /></div>
  //           <div><input id='1' type= 'boxMatch' /></div>
  // 					<div style='margin-left: 380px;'><b>a.</b> They are not my dogs.</div>
  //         </div>

  // 				<div style='display: flex;padding-top: 5mm'>
  //           <div><b>2</b> It isn’t banana.</div>
  // 					<div><input id='2' type= 'boxMatch' /></div>
  //           <div><input id='3' type= 'boxMatch' /></div>
  // 					<div style='margin-left: 383px;'><b>b.</b> I do not have blue eyes.</div>
  //         </div>

  // 				<div style='display: flex;padding-top: 5mm'>
  //           <div ><b>3</b>  They aren’t my dogs.</div>
  // 					<div><input id='4' type= 'boxMatch' /></div>
  //           <div><input id='0' type= 'boxMatch' /></div>
  // 					<div style='margin-left: 300px;'><b>c.</b> I do not like fish.</div>
  //         </div>
  // 				<div style='display: flex;padding-top: 5mm'>
  //           <div ><b>4</b> I don’t have blue eyes.</div>
  // 					<div><input id='6' type= 'boxMatch' /></div>
  //           <div><input id='7' type= 'boxMatch' /></div>
  // 					<div style='margin-left: 278px;'><b>d.</b> It is not a banana.</div>
  //         </div>
  //       </div>
  //     `,
  //   },
  // },
  // 4: {
  //   unit: 'Review 2',
  //   id: 'SB4-2023-R2-P50-E1',
  //   audio: '',
  //   video: '',
  //   component: Match_Write,
  //   exerciseKey: 'img/FriendsPlus/Page50/E4/Key/answerKey.png',
  //   titleQuestion: [
  //     {
  //       num: '',
  //       title: "<img src='img/FriendsPlus/Page50/E4/15.jpg' /> <br> <img src='img/FriendsPlus/Page50/E4/1.jpg' /> ",
  //       color: '',
  //     },
  //   ],
  //   question: {
  //     Match: {
  //       answers: ['0-5', '1-4', '2-7', '3-6'],
  //       coordinates: [
  //         { x: 222, y: 200 },
  //         { x: 444, y: 200 },
  //         { x: 666, y: 200 },
  //         { x: 888, y: 200 },
  //         { x: 222, y: 400 },
  //         { x: 444, y: 400 },
  //         { x: 666, y: 400 },
  //         { x: 888, y: 400 },
  //       ],
  //       // height: 600,
  //       isHorizontal: false,
  //       width: 1110,
  //     },
  //     Write: {
  //       answers: ['hello', 'hi', 'now', 'future'],
  //       commonStyles: {},
  //     },
  //     Layout: `
  //       <div>
  //       <input id='0' />
  //       <input id='1' />
  //       <canvas></canvas>
  //       <input id='2' />
  //       <input id='3' width='50px' />
  //       </div>
  //     `,
  //   },
  // },

  4: {
    unit: "Review 2",
    id: "SB4-2023-RV2-P51-E4",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "4",
        title:
          "Look, ask, and answer. <img style='width:1cm' src='img/FriendsPlus/Page11/E4/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page51/E4/1.jpg" }]],
  },
  5: {
    unit: "Review 2",
    id: "SB4-2023-R2-P51-E2",
    audio: "",
    video: "",
    isHiddenCheck: true,
    exerciseKey: "",
    component: T6,
    // maxLength: 7,
    inputSize: 40,
    textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: "5",
        title:
          "Look and write. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <span style='font-style:italic;color:rgb(25 181 239)' >&ensp;The monkeys are playing with a ball&ensp;</span>
        <div >
          <textarea id='0' rows='5'></textarea>
        </div>
        
        `,
        answer: [""],
      },
    ],
  },

  6: {
    unit: "Review 2",
    id: "SB4-2023-R2-P51-E6",
    audio: "",
    video: "",
    component: MatchDots,
    exerciseKey: "img/FriendsPlus/Page51/E6/Key/answerKey.png",
    stylesTextInput: { borderBottom: "dotted" },
    inputSize: 700,
    maxLength: 200,
    checkUppercase: true,
    titleQuestion: [
      {
        num: "6",
        title:
          "Match the words with the same sound. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    question: {
      DrawLines: {
        multipleLine: false,
        boxMatch: [
          {
            boxMatchStyle: {
              position: "absolute",
              top: "25px",
              left: "200px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 0
          {
            boxMatchStyle: {
              position: "absolute",
              top: "25px",
              left: "414px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 1
          {
            boxMatchStyle: {
              position: "absolute",
              top: "74px",
              left: "260px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 2
          {
            boxMatchStyle: {
              position: "absolute",
              top: "74px",
              left: "414px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 3
          {
            boxMatchStyle: {
              position: "absolute",
              top: "122px",
              left: "220px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 4
          {
            boxMatchStyle: {
              position: "absolute",
              top: "122px",
              left: "414px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 5
          {
            boxMatchStyle: {
              position: "absolute",
              top: "175px",
              left: "211px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 6
          {
            boxMatchStyle: {
              position: "absolute",
              top: "175px",
              left: "414px",
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              border: "1px solid #2eb6e1",
              background: "white",
            },
          }, // 7
        ],
        answers: ["0-7", "1-2", "3-4", "5-6"],
        initialValue: [],
      },
      Layout: `
        <div style='line-height:50px;display:flex;font-size:30px'>
          <div style='margin:0px 200px 50px 100px'>
          
            <b>1.</b> fox<br> 
            <b>2.</b> window<br>
            <b>3.</b> crab<br>
            <b>4.</b> zoo<br>
          </div>
          <div >
            <b>a.</b> web<br>
            <b>b.</b> crayon<br>
            <b>c.</b> zebra<br>
            <b>d.</b> box<br>
          </div>
          <input id='0' type='boxMatch'/>
          <input id='1' type='boxMatch'/>
          <input id='2' type='boxMatch'/>
          <input id='3' type='boxMatch'/>
          <input id='4' type='boxMatch'/>
          <input id='5' type='boxMatch'/>
          <input id='6' type='boxMatch'/>
          <input id='7' type='boxMatch'/>

        </div>
      `,
    },
  },
};
export default json;
