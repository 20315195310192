import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import MatchDots from "../../components/ExcerciseTypes/LineTo/MatchDots";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import UI from "../../components/ExcerciseTypes/Design/UserInterface";
const json = {
  4: {
    // Exercise num
    unit: "Review 4",
    id: "SB4-2023-RV4-P95-E4",
    audio: "",
    video: "",
    textAlign: "center",
    component: T6,
    maxLength: 1,
    inputSize: 40,
    exerciseKey: "img/FriendsPlus/Page95/E4/Key/answerKey.png",

    questionImage: [],
    checkUppercase: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "Read and write T(true) or F(false). <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questions: [
      {
        title: `
          <div style='width:20cm'>
            This is my grandma. Her name is Nga. When my grandma was a little girl, she
            was young and cute. Her hair was long and black. Her face was around and pretty. 
            Her eyes were brown and bright. Now my grandma is seventy years old. Her hair is
            short and grey but she is still pretty. She likes reading books. She likes keeping the 
            house clean. I love my grandma.  
          </div>      
          <div style='margin:20px 0px'>
            <b>1.</b>&ensp;When Grandma was little, her hair was long and black.<u style='color:rgb(25 181 239)' >&ensp;T&ensp;</u><br>
            <b>2.</b>&ensp;Her face was square. #<br>
            <b>3.</b>&ensp;Her eyes were black and bright. #<br>
            <b>4.</b>&ensp;Now Grandma seventy-five years old. #<br>
            <b>5.</b>&ensp;Grandma’s house is clean#
          </div>      
        
        
        
        `,
        answer: ["F", "F", "F", "T"],
      },
    ],
  },
  5: {
    // Exercise num
    unit: "Review 4",
    id: "SB4-2023-RV4-P95-E5",
    audio: "",
    video: "",
    // textAlign: "center",
    component: T6,
    maxLength: 28,
    inputSize: 370,
    exerciseKey: "img/FriendsPlus/Page95/E5/Key/answerKey.png",

    questionImage: [],
    checkUppercase: true,
    titleQuestion: [
      {
        num: "5",
        title:
          "Read the text in  exercise 3 again. Then write the correct sentences. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questions: [
      {
        title: `
          <div style='width:20cm'>
            My name is Mai. I like looking at old photos. Here are some old photos of my 
            family. This is a photo of my brother when he was a baby. He was very cute. He
            was a happy baby. And here are my parents when they were young. My dad was handsome
             and my mom was pretty. They weren’t police officers in this photo. They were 18 years old
             and they were students. And here is me. I was three years old here. I was very shy.
          </div>
              
          <div style='margin:20px 0px'>
            <b>1.</b>&ensp;The photos are new.<u style='color:rgb(25 181 239)' >&ensp;X The photos are old.&ensp;</u><br>
            <b>2.</b>&ensp;Mai's brother was handsome. #<br>
            <b>3.</b>&ensp;Mai's parents were police officers. #<br>
            <b>4.</b>&ensp;Mai wasn't shy. #<br>
          </div>      
        
        
        
        `,
        answer: [
          "Mai's brother was cute.",
          "Mai's parents were students.",
          "Mai was shy.",
        ],
      },
    ],
  },

  6: {
    // Exercise num
    unit: "Review 4",
    id: "SB4-2023-RV4-P95-E6",
    audio: "",
    video: "",
    component: UI,
    // component: DesignUnderLine1,
    recorder: true,
    titleQuestion: [
      {
        num: "6",
        title:
          "Look and say. <img style='width:1cm' src='img/FriendsPlus/Page11/E4/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      // Row
      [{ url: "img/FriendsPlus/Page95/E6/1.jpg" }],
    ],
  },
  7: {
    // Exercise num
    unit: "Review 4",
    id: "SB4-2023-RV4-P95-E7",
    audio: "",
    video: "",
    // textAlign: "center",
    component: T6,
    textareaStyle: { width: 800 },
    maxLength: 28,
    inputSize: 370,
    hideBtnFooter: true,
    questionImage: [],
    checkUppercase: true,
    titleQuestion: [
      {
        num: "7",
        title:
          "What do you and your friends like doing? Write 20-30 words. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questions: [
      {
        title: `
           <div style='color:rgb(3 176 238); font-style:italic'>
              I like playing with my friend Trung. We like ...
           </div>
           <div>
            <textarea id='0' rows='4'></textarea>
           </div>
        
        
        
        `,
        answer: [""],
      },
    ],
  },
};
export default json;
