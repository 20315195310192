import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 7",
    id: "SB4-2023-U7-P57-E1",
    audio: "Audios/Page57/audio-e1.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen, point, and repeat. <headphone name='90' src='Audios/Page57/tieude-e1.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [
        {
          url: "img/FriendsPlus/Page57/E1/1.jpg",
          audioUrl: "Audios/Page57/ring.mp3",
        },
        {
          url: "img/FriendsPlus/Page57/E1/2.jpg",
          audioUrl: "Audios/Page57/bang.mp3",
        },
        {
          url: "img/FriendsPlus/Page57/E1/3.jpg",
          audioUrl: "Audios/Page57/long.mp3",
        },
        {
          url: "img/FriendsPlus/Page57/E1/4.jpg",
          audioUrl: "Audios/Page57/treasure.mp3",
        },
        {
          url: "img/FriendsPlus/Page57/E1/5.jpg",
          audioUrl: "Audios/Page57/measure.mp3",
        },
        {
          url: "img/FriendsPlus/Page57/E1/6.jpg",
          audioUrl: "Audios/Page57/explosion.mp3",
        },
      ],
    ],
  },
  2: {
    unit: "Unit 7",
    id: "SB4-2023-U7-P57-E2",
    audio: "Audios/Page57/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and chant. <headphone name='91' src='Audios/Page57/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page57/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 7",
    id: "SB4-2023-U7-P57-E3",
    audio: "",
    video: "",
    isHiddenCheck: true,
    textAlign: "center",
    exerciseKey: "img/FriendsPlus/Page57/E3/Key/answerKey.png",
    component: T6,
    maxLength: 9,
    inputSize: 130,
    titleQuestion: [
      {
        num: "3",
        title:
          "Read the chant again. Say the words with -ng, -sion, and -sure. Write. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img  src='img/FriendsPlus/Page57/E2/1.jpg'/>
        <div style='text-align:center'>#,#,#,#<br>#,#,#,#</div>
        
        `,
        answer: [
          "measure / treasure / swing / strong / bang / explosion / sing / song",
          "measure / treasure / swing / strong / bang / explosion / sing / song",
          "measure / treasure / swing / strong / bang / explosion / sing / song",
          "measure / treasure / swing / strong / bang / explosion / sing / song",
          "measure / treasure / swing / strong / bang / explosion / sing / song",
          "measure / treasure / swing / strong / bang / explosion / sing / song",
          "measure / treasure / swing / strong / bang / explosion / sing / song",
          "measure / treasure / swing / strong / bang / explosion / sing / song",
        ],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "SB4-2023-U7-P57-E4",
    audio: "Audios/Page57/audio-e4.mp3",
    video: "",
    isHiddenCheck: true,
    exerciseKey: "img/FriendsPlus/Page57/E4/Key/answerKey.png",
    component: T6,
    maxLength: 4,
    textAlign: "center",
    inputSize: 70,
    titleQuestion: [
      {
        num: "4",
        title:
          "Listen and complete the words. <headphone name='92' src='Audios/Page57/tieude-e4.mp3'></headphone> <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        
        <img src='img/FriendsPlus/Page57/E4/1.jpg'/>
        <div style='display:flex; margin:10px 0px'>
          <div>
            <img  src='img/FriendsPlus/Page57/E4/2.jpg'/>
            <div style='text-align:center'>
                mea<u style='color:rgb(25 181 239)' >sure</u>
            </div>
          </div>
          <div style='margin:0px 20px'>
            <img  src='img/FriendsPlus/Page57/E4/3.jpg'/>
            <div style='text-align:center'>
                ri#
            </div>
          </div>
          <div>
            <img  src='img/FriendsPlus/Page57/E4/4.jpg'/>
            <div style='text-align:center'>
                lo#
            </div>
          </div>
        </div>
        <div style='display:flex'>
          <div>
            <img  src='img/FriendsPlus/Page57/E4/5.jpg'/>
            <div style='text-align:center'>
                explo#
            </div>
          </div>
          <div style='margin:0px 20px'>
            <img  src='img/FriendsPlus/Page57/E4/6.jpg'/>
            <div style='text-align:center'>
              ba#
            </div>
          </div>
          <div>
            <img  src='img/FriendsPlus/Page57/E4/7.jpg'/>
            <div style='text-align:center'>
                trea#
            </div>
          </div>
        </div>
        
        
        `,
        answer: ["ng", "ng", "sion", "ng", "sure"],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "SB4-2023-U7-P57-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page57/E5/1.jpg" }]],
  },
};

export default json;
