import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";

const json = {
  1: {
    unit: "Unit 7",
    id: "SB4-2023-U7-P58-E1",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page58/E1/Key/answerKey.png",
    component: T6,
    checkUppercase: true,
    maxLength: 5,
    textAlign: "center",
    textareaStyle: { width: 700 },
    inputSize: 100,
    titleQuestion: [
      {
        num: "1",
        title: "Look at the picture. What is the weather like?",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <img src='img/FriendsPlus/Page58/E1/1.jpg'/>
        <div style='text-align:center'>
            #,#,#,#,#
        </div>
        
        
        `,
        answer: ["cold", "sunny", "hot", "windy", "rainy"],
      },
    ],
  },
  2: {
    unit: "Unit 7",
    id: "SB4-2023-U7-P58-E2",
    audio: "Audios/Page58/audio-e2.mp3",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title:
          "Listen and read. <headphone name='93' src='Audios/Page58/tieude-e2.mp3'></headphone>",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page58/E1/1.jpg" }]],
  },
  3: {
    unit: "Unit 7",
    id: "SB4-2023-U7-P58-E3",
    audio: "",
    video: "",
    exerciseKey: "img/FriendsPlus/Page58/E3/Key/answerKey.png",
    component: T6,
    maxLength: 1,
    textAlign: "center",
    inputSize: 40,
    titleQuestion: [
      {
        num: "3",
        title:
          "Read again. Match. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        
        
        <div style='display:flex' >
          <img style='height:15cm'  src='img/FriendsPlus/Page58/E1/1.jpg'/>
          <div style='margin:18px 0px'>
           
            <img  src='img/FriendsPlus/Page58/E3/1.jpg'/><br>
            <img  src='img/FriendsPlus/Page58/E3/2.jpg'/><br>
            <img  src='img/FriendsPlus/Page58/E3/3.jpg'/><br>
            <img  src='img/FriendsPlus/Page58/E3/4.jpg'/>
          </div>
        </div>
        <div style='display:flex; justify-content:space-around;line-height:50px'>
          <div>
            <b>1.</b> Put on a scarf. <u style='color:rgb(25 181 239)' >&ensp;b&ensp;</u> <br> 
            <b>2.</b> Put on a raincoat. # <br> 
          </div>
          <div>
            <b>3.</b> Put on a sun hat. #<br>
            <b>4.</b> Don’t put on a hat. #<br>
          </div>
        </div>
           
            
            
        `,
        answer: ["c", "d", "a"],
      },
    ],
  },
  4: {
    unit: "Unit 7",
    id: "SB4-2023-U7-P58-E4",
    audio: "",
    video: "",
    isHiddenCheck: true,
    exerciseKey: "",
    component: T6,
    // maxLength: 7,
    inputSize: 40,
    hideBtnFooter:true,
    textareaStyle: { width: 800 },
    titleQuestion: [
      {
        num: "4",
        title: "What’s your favorite weather? Why?",
        color: "#203c8f",
      },
    ],
    questionImage: [],
    // hideBtnFooter: true,
    questions: [
      {
        title: `
        <div >
          <textarea id='0' rows='5'></textarea>
        </div>  
        
        `,
        answer: [""],
      },
    ],
  },
  5: {
    unit: "Unit 7",
    id: "SB4-2023-U7-P58-E5",
    audio: "",
    video: "",
    recorder: true,
    component: UI,
    // titleQuestion: [
    //   {
    //     num: "5",
    //     title:
    //       "Look again and write.  <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
    //     color: "#203c8f",
    //   },
    // ],
    questionImage: [[{ url: "img/FriendsPlus/Page58/E5/1.jpg" }]],
  },
};

export default json;
