import UI from "../../components/ExcerciseTypes/Design/UserInterface";
import DesignUnderLine from "../../components/ExcerciseTypes/Design/DesignUnderLine";
import T6 from "../../components/ExcerciseTypes/TypeIn/T6";
import Circle_Write from "../../components/ExcerciseTypes/Circle_Write";
import D1 from "../../components/ExcerciseTypes/Design/TypeIn";

const json = {
  1: {
    unit: "Unit 4",
    id: "SB4-2023-U4-P37-E1",
    audio: "Audios/Page37/audio-e1.mp3",
    video: "",
    component: D1,
    maxLength: 1,
    fontSize: 27,
    exerciseKey: "img/FriendsPlus/Page37/E1/Key/answerKey.png",
    titleQuestion: [
      {
        num: "1",
        title:
          "Listen and write the numbers. <headphone name='57' src='Audios/Page37/tieude-e1.mp3'></headphone> <img style='width:1cm' src='img/FriendsPlus/Page11/E3/b.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [{ url: "img/FriendsPlus/Page37/E1/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page37/E1/2.jpg" },
        { url: "img/FriendsPlus/Page37/E1/3.jpg", input: true, answer: "2" },
        { url: "img/FriendsPlus/Page37/E1/4.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page37/E1/5.jpg" }],
      [
        { url: "img/FriendsPlus/Page37/E1/6.jpg" },
        { url: "img/FriendsPlus/Page37/E1/7.jpg", input: true, answer: "3" },
        { url: "img/FriendsPlus/Page37/E1/8.jpg" },
        { url: "img/FriendsPlus/Page37/E1/9.jpg", input: true, answer: "4" },
        { url: "img/FriendsPlus/Page37/E1/10.jpg" },
      ],
    ],
  },

  2: {
    unit: "Unit 4",
    id: "SB4-2023-U4-P37-E2",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "2",
        title: "Point, ask and answer about your school.",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page37/E2/1.jpg" }]],
  },
  3: {
    unit: "Unit 4",
    id: "SB4-2023-U4-P37-E3",
    audio: "",
    video: "",
    component: UI,
    recorder: true,
    question: [],
    titleQuestion: [
      {
        num: "3 ",
        title: "Talk about your week.",
        color: "#203c8f",
      },
    ],
    questionImage: [[{ url: "img/FriendsPlus/Page37/E3/1.jpg" }]],
  },
  4: {
    unit: "Unit 4",
    id: "SB4-2023-U4-P37-E4",
    audio: "",
    video: "",
    // exerciseKey: "img/FriendsPlus/Page37/E4/Key/answerKey.png",
    component: D1,
    // maxLength:
    hideBtnFooter: true,
    titleQuestion: [
      {
        num: "4",
        title:
          "Write about a family member’s job. Use the example to help you. <img style='width:1cm' src='img/FriendsPlus/Page11/E3/a.jpg'/>",
        color: "#203c8f",
      },
    ],
    questionImage: [
      [{ url: "img/FriendsPlus/Page37/E4/1.jpg" }],
      [
        { url: "img/FriendsPlus/Page37/E4/2.jpg" },
        { url: "img/FriendsPlus/Page37/E4/3.jpg", input: true, answer: "" },
        { url: "img/FriendsPlus/Page37/E4/4.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page37/E4/5.jpg" },
        { url: "img/FriendsPlus/Page37/E4/6.jpg", input: true, answer: "" },
        { url: "img/FriendsPlus/Page37/E4/7.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page37/E4/8.jpg" },
        { url: "img/FriendsPlus/Page37/E4/9.jpg", input: true, answer: "" },
        { url: "img/FriendsPlus/Page37/E4/10.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page37/E4/11.jpg" },
        { url: "img/FriendsPlus/Page37/E4/12.jpg", input: true, answer: "" },
        { url: "img/FriendsPlus/Page37/E4/13.jpg" },
      ],
      [
        { url: "img/FriendsPlus/Page37/E4/14.jpg" },
        { url: "img/FriendsPlus/Page37/E4/15.jpg", input: true, answer: "" },
        { url: "img/FriendsPlus/Page37/E4/16.jpg" },
      ],
      [{ url: "img/FriendsPlus/Page37/E4/17.jpg" }],
    ],
  },
};

export default json;
